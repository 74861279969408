<style scoped lang='scss'>
.swiper-container{
  width: 1400px;
}
.titlePaddingTop{
  padding-top: 130px;
}
.swiper-buttons{
  color: #666;
}

@keyframes mymove2
{
  0% {z-index:-1;opacity:0;}
  25% {z-index:0;opacity:0.25;}
  50% {z-index:1;opacity:0.5;}
  75% {z-index:2;opacity:0.75;}
  100% {z-index:3;opacity:1;}
}
@-webkit-keyframes mymove2
{
  0% {z-index:-1;opacity:0;}
  25% {z-index:0;opacity:0.25;}
  50% {z-index:1;opacity:0.5;}
  75% {z-index:2;opacity:0.75;}
  100% {z-index:3;opacity:1;}
}
#indexPage{
  overflow-x: hidden;
}
.index_banner{
  width:100%;
  min-width:1400px;
  min-height:568.75px;
  position: relative;
  overflow: hidden;
  .banner{width:100%;}
  .index_banner1{
    z-index:3;
    visibility: hidden;
  }
  .index_banner1.changeWid{
    visibility: visible;
  }
  .index_banner1.changeWid+.img-flex{
    display: none;
  }
  .index_banner2{
    position:absolute;
    left:0;
    top:0;
    z-index:-1;
    opacity:0.5;
    animation:mymove2 2.5s;
    -moz-animation:mymove2 2.5s; /* Firefox */
    -webkit-animation:mymove2 2.5s; /* Safari and Chrome */
    -o-animation:mymove2 2.5s; /* Opera */
    animation-iteration-count:1;/*动画只执行一次*/
    -moz-animation-iteration-count:1;
    -webkit-animation-iteration-count:1;
    -o-animation-iteration-count:1;
    animation-fill-mode:forwards;
  }
  .banner_titlBox{
    position: absolute;
    top: 37%;bottom: 42%;
    left: 0;right: 0;
  }
  .banner_title{
    text-align: center;
    z-index: 4;
  }
  .banner_title1{
    width: 100%;
    color:white;
    font-size: $Size68;
  }
  .banner_title2{
    width: 600px;
    height: 64px;
    left: calc(50% - 302px);
    font-size: $Size42;
    color: white;
    img{width: 100%;}
    .banner_title2_wz{
      width: 600px;
      line-height:64px;
      z-index: 5;
      position: relative;
      top:0px;
      font-size: 40px;
    }
  }

}
.business{
  min-width:1400px;
  width:100%;
  color: $indexColor222;
  text-align: center;
  background-color: #fff;
  padding-bottom:120px;
  .business_title{
    font-size: $Size50;
  }
  .business_title_sm{
    font-size: $Size24;
    margin-top: 20px;
  }
  .businessList{
    width: 1400px;
    margin: 0 auto;
    margin-top: 50px;
    .businessItem{
      width: 451px;
      height: 614px;
      position: relative;
      text-align: left;
      color: white;
      overflow: hidden;
      .businessItemImg{
        width:100%;
        -webkit-transition:all 1s;
        -moz-transition:all 1s;
        -o-transition:all 1s;
      }

      .businessItem_main{
        position: absolute;
        left: 0;
        top: 0;
        width: 451px;
        height: 614px;
        padding:68px 36px 35px;

        .businessItem_title{
          font-size: $Size30;
        }
        .businessItem_line{width:75px;height:3px;background-color:white;margin: 34px auto 40px 0;}
        .businessItem_explain{
          font-size: $Size18;
          line-height:32px;
        }
        .businessItem_more{
          position:absolute;
          bottom:36px;
          right:48px;
          font-size: $Size18;
          cursor: pointer;
        }
      }
    }

    .businessItem:hover .businessItemImg{
      -webkit-transform:scale(1.2);
      -moz-transform:scale(1.2);
      -o-transform:scale(1.2);
    }
  }
}
.case{
  width:100%;
  background-color: #f4f4f4;
  color: $indexColor222;
  padding-bottom: 60px;
  padding-top: 100px;
  position: relative;
  .case_title{
    font-size: $Size50;
    margin-bottom: 40px;
  }
  .swiper-container{padding: 15px 0;width:calc(100% - 28px);margin: 14px auto;}
  .swiper-slide{
    width: 25% !important;
    background-color: #fff;
    cursor: pointer;
    img{width: 100%;}
    .item_title{
      height: 1px;
      width: 100%;
      position: relative;
      span{
        position: absolute;
        right: 10%;
        top: -55px;
        font-size: 18px;
      }
    }
  }

}
.advantage{
  width:100%;
  min-width: 1400px;
  padding-bottom: 20px;
  background: url('../assets/images/global/advantageBg.jpg') no-repeat;
  background-size: cover;
  color: white;
  .indexMainTitle,.indexMainTitle_second{color: white;}
  .advantage_titleMs{
    margin-top:23px;
    margin-bottom: 100px;
  }
  .advantage_main{
    width: 1400px;
    margin: 0 auto;
  }
  .advantage_main_item{
    width: 440px;
    height:400px;
    img{margin: 0 auto;}
    .advantage_item-title{
      font-size: $Size34;
      margin-top: $Size48;
      margin-bottom: $Size20;
    }
    .advantage_item-ms{
      font-size: $Size18;
      line-height: 28px;
    }
  }
}
.process{
  width:100%;
  min-width: 1400px;
  padding-bottom: 20px;
  background-color: #fff;
  .process_title{
    margin-bottom: 15px;
  }
  .process_title_sm{margin-bottom: 80px;}
  .processList{
    width: 1168px;
    margin: 0 auto;
    .process_item{
      width: 232px;
      height: 600px;
      position: relative;
      img{
        height: 232px;
      }
      .process_item_top{
        width: 232px;
        height: 232px;
        position: absolute;
        left: 0;
        top: 0;
        img{width: 100px;height: 100px;margin: 0 auto 2px;}
        div{font-size: 28px;color: #2b89d8;}
      }
      .process_list{
        border-left: 2px dashed #999;
        width: 200px;
        margin: 40px auto 0;
        div{
          line-height: 45px;
          font-size: 26px;
          text-align: left;
          padding-left: 40px;
        }
      }
    }
  }
}
.news{
  padding-bottom: 120px;
  position: relative;

  .nes_title{margin-bottom:58px;}
  .swiper-container{padding: 15px;}
  .swiper-slide{
    width: 450px;
    height: 530px;
    position: relative;
    background-color: #fff;
    border: 1px solid #eee;
    img{width: 100%;}
    .swiper_wz{padding: 30px;}
    .swiper_ms{
      line-height: 24px;
      color: #333;
      // 做处理---超出2行的部分用省略号展示
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
    .swiper_date{
      font-size: $Size14;
      color: $indexColor999;
      position: absolute;
      bottom: 28px;
      left: 30px;
    }
  }
  .swiper-button-prev{
    top: 533px;
    left: calc(50% - 737px);
  }
  .swiper-button-next{
    top: 533px;
    left: calc(50% + 710px);
  }
  .swiper-slide:hover{
    -webkit-box-shadow: 0px 0px 25px rgba(0,0,0,0.1) ;
    -moz-box-shadow:  0px 0px 25px rgba(0,0,0,0.1);
    box-shadow:  0px 0px 25px rgba(0,0,0,0.1);
  }
}
</style>
<template>
  <div class="indexPage" id="indexPage">
    <div class="index_banner">
      <!-- <img class="banner index_banner2" src="@assets/images/global/banner_bg2.jpg" alt=""> -->
      <img class="banner index_banner1" :class="{changeWid, changeWid}" src="@assets/images/global/banner_bg2.jpg" alt="">
      <video muted autoplay="autoplay" loop="loop" class="aaa" style="width:100%;height:auto;z-index:4;position: absolute;top:0;left:0;">
        <source src="@assets/images/video/banner.mp4" type="video/mp4">
      </video>
      <!-- <div class="img-flex"></div> -->
      <div class="banner_titlBox acea-row row-center row-middle">
        <transition name="el-fade-in-linear">
          <div class="banner_title banner_title1 weight wow fadeInUp" data-wow-delay="2s">智慧产品方案服务商</div>
        </transition>
        <transition name="el-fade-in-linear">
          <div class="banner_title banner_title2 wow fadeInUp" data-wow-delay="2.2s">
            <div class="banner_title2_wz">搭建技术桥梁&nbsp;&nbsp;创造幸福社会</div>
          </div>
        </transition>
      </div>
    </div>
    <div class="business titlePaddingTop" ref="business">
      <div class="business_title weight wow fadeInUp" data-wow-delay="0.2s">果果慧业务</div>
      <div class="business_title_sm wow fadeInUp" data-wow-delay="0.3s">果果慧秉持“多元化、合作、共赢”的原则，致力于成为服务全球的智慧产品方案服务商</div>
      <div class="businessList acea-row row-between-wrapper">
          <div class="businessItem wow fadeInUp" :data-wow-delay="0.5*(index+1)+'s'" v-for="(item, index) in businessList" :key="index">
            <img class="businessItemImg" v-lazy="item.img" alt="">
            <div class="businessItem_main">
              <div class="businessItem_title">{{item.title}}</div>
              <div class="businessItem_line"></div>
              <div class="businessItem_explain" v-if="item.explainlist.length>0">
                <div v-for="(items,indexx) in item.explainlist" :key="indexx">
                  {{items}}
                </div>
              </div>
              <div class="businessItem_more" @click="toBusiness(item.type)">了解更多&nbsp;》</div>
            </div>
          </div>
      </div>
    </div>
    <div class="case textCenter titlePaddingTop" ref="case">
      <div class="case_title weight wow fadeInUp" data-wow-delay="0.2s">成功案例</div>
      <swiper class="swiper wow fadeInUp" data-wow-delay="0.4s" :options="swiperOption1">
        <swiper-slide class="acea-row row-middle"
          v-for="(item, index) in products" :key="index">
          <img :src="item.productBigImg" alt="" :data-category="item.category" :data-sort="item.sort">
          <div class="item_title">
            <span>{{item.name}}</span>
          </div>
        </swiper-slide>
      </swiper>
    </div>
    <div class="advantage textCenter titlePaddingTop" ref="advantage">
      <div class="advantage_title indexMainTitle weight wow fadeInUp" data-wow-delay="0.2s">果果慧优势</div>
      <div class="advantage_titleMs indexMainTitle_second wow fadeInUp" data-wow-delay="0.3s">只有创新和坚持，才能取得成功</div>
      <div class="advantage_main acea-row row-between-wrapper">
        <div class="advantage_main_item">
          <img src="@/assets/images/global/advantage01.png" alt="" class="wow flipInX" data-wow-delay="0.2s">
          <div class="advantage_item-title wow fadeInUp" data-wow-delay="0.4s">国际化市场</div>
          <div class="advantage_item-ms wow fadeInUp" data-wow-delay="0.5s">
            对接全球客户需求，
            <br>
            提供国际化的认证支持，产品出口海内外
          </div>
        </div>
        <div class="advantage_main_item">
          <img src="@/assets/images/global/advantage02.png" alt="" class="wow flipInX" data-wow-delay="0.2s">
          <div class="advantage_item-title wow fadeInUp" data-wow-delay="0.4s">专业化技术</div>
          <div class="advantage_item-ms wow fadeInUp" data-wow-delay="0.5s">
            拥有设计、结构、软硬件开发人才，
            <br>
            提供最合适的动态结构设计和定制化软硬件平台
          </div>
        </div>
        <div class="advantage_main_item">
          <img src="@/assets/images/global/advantage03.png" alt="" class="wow flipInX" data-wow-delay="0.2s">
          <div class="advantage_item-title wow fadeInUp" data-wow-delay="0.4s">全面化供应链</div>
          <div class="advantage_item-ms wow fadeInUp" data-wow-delay="0.5s">
            供应链遍及电子物料、结构料等物料供应商，
            <br>
            及制板、贴片、模房、组装、物流等产品制造所有环节
          </div>
        </div>
      </div>
    </div>
    <div class="process textCenter titlePaddingTop" ref="process">
      <div class="process_title indexMainTitle weight wow fadeInUp" data-wow-delay="0.2s">业务流程</div>
      <div class="process_title_sm indexMainTitle_second wow fadeInUp" data-wow-delay="0.3s">从设计到量产全生命周期服务商</div>
      <div class="processList acea-row row-between-wrapper">
        <div class="process_item wow fadeInUp" :data-wow-delay="(0.2*index+0.85)+'s'" v-for="(item, index) in processList" :key="index">
          <img v-lazy="item.bgImg" alt="">
          <div class="process_item_top acea-row row-column row-center">
            <img v-lazy="item.processIcon" alt="">
            <div class="">{{item.processName}}</div>
          </div>
          <div class="process_list wow fadeInUp">
            <div v-for="(itemx, indexx) in item.processStep" :key="indexx">{{itemx}}</div>
          </div>
        </div>

      </div>
    </div>
    <div class="news textCenter titlePaddingTop" ref="news">
      <div class="nes_title indexMainTitle weight wow fadeInUp" data-wow-delay="0.2s">新闻资讯</div>
      <swiper class="swiper wow fadeInUp" data-wow-delay="0.4s" :options="swiperOption2">
        <swiper-slide>
          <img src="@/assets/images/global/news03.png" alt="">
          <div class="swiper_wz textLeft">
            <div class="swiper_ms">深圳公司搬迁至科技园北区紫光信息港</div>
            <div class="swiper_date">2017/03/12 </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <a href="https://www.zuiki.co.jp/products/mascon/" target="_blank">
            <img src="@/assets/images/global/news02.png" alt="">
            <div class="swiper_wz textLeft">
              <div class="swiper_ms">日本关联公司产品「電車ＧＯ！！專用主控台控制器for Nintendo Switch」正式预售！</div>
              <div class="swiper_date">2021/08/05</div>
            </div>
          </a>
        </swiper-slide>
        <swiper-slide>
          <img src="@/assets/images/global/news01.png" alt="">
          <div class="swiper_wz textLeft">
            <div class="swiper_ms">日本关联公司与知名游戏品牌合作开发的EgretⅡ mini旋转屏游戏机正式发售</div>
            <div class="swiper_date">2022/03/02</div>
          </div>
        </swiper-slide>
      </swiper>
      <div class="swiper-buttons swiper-button-prev wow fadeInUp" data-wow-delay="0.4s" slot="button-prev"></div>
      <div class="swiper-buttons swiper-button-next wow fadeInUp" data-wow-delay="0.4s" slot="button-next"></div>
    </div>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import $ from 'jquery'
export default {
  components: {
    Swiper,
    SwiperSlide
  },
  data () {
    let _that = this;
    return {
      url: '',// 当前页面地址
      // 获取的商品要按事件排序，某个品类下最新的排在最前面
      products:[
        {
          productid: 'p10000',// 产品id
          productBigImg: require('../assets/images/global/case_00.jpg'),
          name: '自主品牌机车摇杆手柄',
          category: 'obm_games',
          sort: 1,
          categoryname: '自主品牌游戏产品',
        },
        {
          productid: 'p10001',// 产品id
          productBigImg: require('../assets/images/global/case_02.jpg'),
          name: '即插即玩摇杆电车',
          category: 'games_periphery',
          sort: 1,
          categoryname: '游戏周边产品解决方案',
        },
        {
          productid: 'p10002',// 产品id
          productBigImg: require('../assets/images/global/case_04.jpg'),
          name: '80年代经典复古街机',
          category: 'games_periphery',
          sort: 2,
          categoryname: '游戏周边产品解决方案',
        },
        {
          productid: 'p10003',// 产品id
          productBigImg: require('../assets/images/global/case_05.jpg'),
          name: '旋转屏复古游戏机',
          category: 'games_periphery',
          sort: 3,
          categoryname: '游戏周边产品解决方案',
        },
        {
          productid: 'p10004',// 产品id
          productBigImg: require('../assets/images/global/case_03.jpg'),
          name: '复古游戏手柄',
          category: 'games_periphery',
          sort: 4,
          categoryname: '游戏周边产品解决方案',
        },
        {
          productid: 'p10005',// 产品id
          productBigImg: require('../assets/images/global/case_06.jpg'),
          name: '复古游戏手柄',
          category: 'games_periphery',
          sort: 5,
          categoryname: '游戏周边产品解决方案',
        },
        {
          productid: 'dz10000',// 产品id
          productBigImg: require('../assets/images/global/case_07.jpg'),
          name: 'PCBA制板',
          category: 'ele_component',
          sort: 1,
          categoryname: 'PCB板及电子元件',
        },
        {
          productid: 'dz10001',// 产品id
          productBigImg: require('../assets/images/global/case_08.jpg'),
          name: '电子物料供应',
          category: 'ele_component',
          sort: 2,
          categoryname: 'PCB板及电子元件',
        },
        {
          productid: 'p10006',// 产品id
          productBigImg: require('../assets/images/global/case_01.jpg'),
          name: '智慧灯罩广告牌',
          category: 'wisdom_display',
          sort: 1,
          categoryname: 'SoC解决方案',
        },
      ],
      swiperOption1: {
        speed:1000,
        spaceBetween: 14,
        slidesPerView:'auto',
        slidesPerGroup: true,
        slidesOffsetBefore: 2,
        // mousewheel: true,
        mousewheel: {
          releaseOnEdges: true,
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        on: {
          click: function(e){
            let category = e.target.getAttribute('data-category');
            let sort = e.target.getAttribute('data-sort');
            if( !category ) return false;
            _that.$router.push({ path: '/products', query:{ type: category, sort: sort }});
          }
        }
      },
      swiperOption2: {
        slidesPerView: 3,
        spaceBetween: 25,
        slidesPerGroup: 1,
        loop: true,
        loopFillGroupWithBlank: false,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      businessList:[
        {
          img:require('../assets/images/global/business1.png'),
          type: 'product',
          title: '嵌入式智慧产品解决方案',
          explainlist: [
            '根据需求，提供最合适的SoC，并结合SoC提供产品的整套解决方案。包括图形图像类产品、游戏周边产品等等。',
            '因使用自主开发的系统平台和游戏引擎等，我们的方案拥有系统优秀、稳定性好、启动速度快的优势。'
          ]
        },
        {
          img:require('../assets/images/global/business2.png'),
          title: '智慧农业',
          type: 'agriculture',
          explainlist: [
            '通过传感器采集温室内温湿度等环境信息，经由无线信号收发模块传输数据，并对数据进行分析处理，以实现对大棚内环境的远程控制。'
          ]
        },
        {
          img:require('../assets/images/global/business3.png'),
          title: '智慧传感器通讯产品',
          type: 'sensor',
          explainlist: ['使用定制芯片和专用模组，提供相应的解决方案，以实现智能配电、全屋智能等。']
        },
      ],
      processList:[
        {
          processName: '产品定义',
          bgImg:require('../assets/images/global/business-lc.png'),
          processIcon:require('../assets/images/global/processIcon1.png'),
          processStep:[
            '市场调研','客户沟通'
          ]
        },
        {
          processName: '产品设计',
          bgImg:require('../assets/images/global/business-lc1.png'),
          processIcon:require('../assets/images/global/processIcon2.png'),
          processStep:[
            'ID设计','结构设计','硬件设计','软件开发','包装设计'
          ]
        },
        {
          processName: '试产验证',
          bgImg:require('../assets/images/global/business-lc1.png'),
          processIcon:require('../assets/images/global/processIcon3.png'),
          processStep:[
            '制作手板','打样验证','测试认证'
          ]
        },
        {
          processName: '量产交付',
          bgImg:require('../assets/images/global/business-lc2.png'),
          processIcon:require('../assets/images/global/processIcon4.png'),
          processStep:[
            '量产','客户验收','出货交付','售后服务'
          ]
        }
      ],
      changeWid:false
    }
  },
  computed: {},
  created(){},
  watch: {
    $route(){
      let _that = this;
      if( _that.$route.query.type ){
        let index = _that.$route.query.type.indexOf('aifrutech');
        let link = index<0?_that.$route.query.type:_that.$route.query.type.substr(0,index);
        let offetTop = _that.$refs[link].offsetTop-66;
        window.scrollTo({ top: offetTop,left: 0,behavior: 'smooth' });
      }else{
        _that.type = ''
      }
    },
  },
  mounted(){
    window.scrollTo({top: 0,left: 0});
    new this.$WOW.WOW({offset: '40'}).init();
    let _that = this;
    if(_that.$route.query.type){
      let index = _that.$route.query.type.indexOf('aifrutech');
      let link = index<0?_that.$route.query.type:_that.$route.query.type.substr(0,index);
      setTimeout(function(){
        let offetTop = _that.$refs[link].offsetTop-66;
        window.scrollTo({top: offetTop,left: 0,behavior: 'smooth'});
      },400)
    }
    // 对屏幕进行监听
    let screenWidth = document.body.clientWidth;
    window.onresize = () => {
      return (() => {
        _that.changeWid = true
      })();
    };
    // console.log($('.banner'));
    // let fragmentConfig = {
    //   container : '.img-flex',//显示容器
    //   line : 10,//多少行
    //   column : 24,//多少列
    //   width : '100%' ,// 显示容器的宽度
    //   animeTime : 2500,//最长动画时间,图片的取值将在 animeTime*0.33 + animeTime*0.66之前取值
    //   img : require('../assets/images/global/banner_bg2.jpg')//图片路径
    // };
    // var container = $(fragmentConfig.container);
    // container.append("<ul class='clearfix'></ul>");
    // container.css({
    //   width : fragmentConfig.width
    // });
		// var containerUl = container.find(" > ul");
		// for(var i = 0;i < (fragmentConfig.line*fragmentConfig.column);i++){
		// 	containerUl.append("<li></li>");
		// }
		// var	containerItem = containerUl.find("li");

		// //加载图片
		// var Img = new Image();
		// 	Img.src = fragmentConfig.img;
		// //图片加载完成时
		// Img.onload = function(){
		// 	var multiple = container.width() / Img.width,
		// 		width = Img.width * multiple,
		// 		height = Img.height * multiple,
		// 		findWidth = width/fragmentConfig.column,
		// 		findHeight = height/fragmentConfig.line;
		// 	var windowWidth = screen.width,windowHeight = screen.height;
		// 	containerItem.css({
		// 		width : findWidth,
		// 		height : findHeight,
		// 		'background-image' : 'url('+Img.src+')',
		// 		'background-size' : width+'px '+height+'px',
		// 		opacity : 0
		// 	});
		// 	container.css({
		// 		left : '50%',
		// 		top : '50%',
		// 		'margin-top' : -height/2,
		// 		'margin-left' : -width/2
		// 	});
		// 	var x,y;
		// 	for(i = 0; i < containerItem.length; i++){
		// 		x = i%fragmentConfig.column;
		// 		y = Math.floor(i/fragmentConfig.column);
		// 		containerItem.eq(i).css({
		// 			'background-position' : -x*findWidth+'px '+(-y*findHeight)+'px',
		// 			left :  Math.ceil(Math.random()*windowWidth*2) - windowWidth,
		// 			top : Math.ceil(Math.random()*windowHeight*2) - windowHeight
		// 		}).animate({
		// 			left : 0,
		// 			top : 0,
		// 			opacity : 1
		// 		},Math.ceil(Math.random()*(0.66*fragmentConfig.animeTime))+(0.33*fragmentConfig.animeTime));
		// 	}
		// }
  },
  methods: {
    toBusiness(type){
      // 获取当前点击的是业务哪一块模块，以便跳转后定位
      this.$router.push({ path: '/business', query:{ type: type }});
    },

  },

}

</script>
